import api from "../helpers/interceptor";

//Sub Admin
export const subAdminClgList = async () =>
  await api.get(`/getActiveIndianColleges`);

export const addSubAdmin = async (payload) =>
  await api.post(`/addSubAdmin`, payload);
export const subAdminPermission = async (payload) =>
  await api.get(`/getSubAdminPermissions`, payload);
export const subadminList = async (payload) =>
  await api.get(
    `/getSubAdmins?search_key=${payload.search_key}&limit=${payload.limit}&page=${payload.page}`
  );
export const getSubAdminDetails = async (payload) =>
  await api.get(`/getSubAdminDetails?admin_id=${payload.admin_id}`);
export const getPlacementCompanies = async (payload) =>
  await api.get(
    `/getPlacementCompanies?limit=${payload.limit}&page=${payload.page}&search_key=${payload?.search_key}`
  );

export const delPlacementConpany = async (payload) =>
  await api.put(`/editPlacementCompany`, payload);

export const addPlacementCompany = async (payload) =>
  await api.post(`/addPlacementCompany`, payload);

export const editPlacement = async (payload) =>
  await api.put(`/editPlacementCompany`, payload);

export const getPlacementDeatailsByid = async (payload) =>
  await api.get(`/getPlacementCompanyDetails?id=${payload.id}`);

export const addBlogs = async (payload) =>
  await api.post(`/blogs/addBlog`, payload);

export const getBlogsList = async (payload = () => {}) =>
  await api.get(`/blogs/getList?limit=${payload.limit}&page=${payload.page}`);

export const getBlogsDetails = async (id) =>
  await api.get(`/blogs/getListById/${id}`);

export const editBlogField = async (id, payload) =>
  await api.put(`/blogs/update-field/${id}`, payload);

export const updateBlog = async (id, payload) =>
  await api.put(`blogs/updateBlog/${id}`, payload);

export const editSubAdmin = async (payload) =>
  await api.put(`/editSubAdminDetails`, payload);
export const subAdminStatus = async (payload) =>
  await api.put(`/changeSubAdminStatus`, payload);
export const getsubAdminsRole = async (payload) =>
  await api.get(`/getSubAdminbyRole?role=${payload.role}`);

// content manager
export const teamLeadDataList = async (payload) =>
  await api.get(
    `/getTeamLeaders?limit=${payload.limit}&page=${payload.page}&search_key=${payload.search_key}`
  );
export const teamLeadList = async (payload) => await api.get(`/getTeamLeaders`);
export const teamLeadDetailData = async (payload) =>
  await api.get(`/getTeamLeaderDetails?admin_id=${payload.admin_id}`);

// intern
export const internsLists = async (payload) =>
  await api.get(
    `/getInterns?limit=${payload.limit}&page=${payload.page}&search_key=${payload.search_key}&tl_id=${payload.tl_id}`
  );
export const getInterns = async (payload) => await api.get(`/getInterns`);
export const internDetails = async (payload) =>
  await api.get(
    `/getInternDetails?admin_id=${payload.admin_id}&type=${payload.type}`
  );

//Assign task
export const taskList = async (payload) =>
  await api.get(
    `/getTasks?task_status=${payload.task_status}&page=${payload.page}&limit=${payload.limit}&search_key=${payload.search_key}`
  );
export const taskDetails = async (payload) =>
  await api.get(`/getTaskDetails?task_id=${payload.task_id}`);
export const addTask = async (payload) => await api.post(`/addTask`, payload);
export const editTask = async (payload) =>
  await api.put(`/editTaskDetails`, payload);
export const myTaskList = async (payload) =>
  await api.get(`/getMyTasks?page=${payload.page}&limit=${payload.limit}`);
export const myTaskDetails = async (payload) =>
  await api.get(`/getMyTaskDetails?task_id=${payload.task_id}`);

//College particular module
export const subAdminModuleData = async (payload) =>
  await api.get(`/getIndianCollegeModules?college_id=${payload.college_id}`);

// Indian college content
export const assigningToOtherList = async (payload) =>
  await api.get(
    `/ICassignToOthers?task_status=${payload.task_status}&search_key=${payload.search_key}&limit=${payload.limit}&page=${payload.page}&module=${payload.module}&assigned_to=${payload.assigned_to}`
  );
// export const assigningToOtherList = async (payload) => await api.get(`/ICassignToOthers?task_status=${payload.task_status}&search_key=${payload.search}&limit=${payload.limit}&page=${payload.page}`);
export const statusAssignToOther = async (payload) =>
  await api.put(`/approveRejectTask`, payload);
export const taskPublished = async (payload) =>
  await api.put(`/publishTask`, payload);
export const assigToMeList = async (payload) =>
  await api.get(
    `/ICassignToMe?task_status=${payload.task_status}&search_key=${payload.search_key}&limit=${payload.limit}&page=${payload.page}&module=${payload.module}&priority=${payload.priority}&created_at=${payload.created_at}&assigned=${payload.assigned}`
  );
export const editPermissionList = async (payload) => {
  return await api.get(`/getAllColleges?search_key=${payload.search_key}&limit=${payload.limit}&page=${payload.page}&college_mode=${payload.college_mode}&verification_status=${payload.verification_status}
&state=${payload.state}&city=${payload.city}&education_level=${payload.education_level}&college_type=${payload.college_type}&university_affiliated=${payload.university_affiliated}&approved_by=${payload.approved_by}&career_cluster=${payload.career_cluster}&course=${payload.course}&degree=${payload.degree_type}&exams_accepted=${payload.exams_accepted}
`);
};
export const checkChangeStatus = async (payload) =>
  await api.post(`/changeCheckStatus`, payload);
export const requestEditPermission = async (payload) =>
  await api.post(`/requestEditPermission`, payload);

// FAQ submit button
export const faqSubmit = async (payload) =>
  await api.post("/submitFAQ", payload);
export const dateSubmit = async (payload) =>
  await api.post("/submitDates", payload);
export const coursesSubmit = async (payload) =>
  await api.post("/submitCourses", payload);
export const scholershipSubmit = async (payload) =>
  await api.post("/submitScholarship", payload);
export const admissionSubmit = async (payload) =>
  await api.post("/submitAdmissionData", payload);

// statics
export const staticsGraph = async (payload) =>
  await api.get(`/getStatistics?type=${payload.type}&year=${payload.year}`);
export const productivityGraph = async (payload) =>
  await api.get(
    `/getProductivityEfficiency?type=${payload.type}&year=${payload.year}`
  );
export const timePieChart = async (payload) =>
  await api.get(
    `/getTimelyCompletionData?type=${payload.type}&year=${payload.year}`
  );
export const reviewsList = async (payload) =>
  await api.get(`/getReviews?page=${payload.page}&limit=${payload.limit}`);
export const calendarApi = async (payload) =>
  await api.get(
    `/getCalenderData?month_name=${payload.month_name}&year=${payload.year}`
  );
export const dashboardActiveTask = async (payload) =>
  await api.get(`/getActiveTasks?type=${payload.type}`);
export const dashboardCards = async (payload) =>
  await api.get(`/getDashboardCards`, payload);

//Profile api
export const profileDetails = async () => await api.get(`/getProfile`);

// single college module assign task cm/tl
export const singleClgModule = async (payload) =>
  await api.get(`/getSingleCollegeModules?college_id=${payload.college_id}`);
export const multipleClgModule = async (payload) =>
  await api.post(`/getMultipleCollegeModules`, payload);
export const removeAccess = async (payload) =>
  await api.post(`/removeICAccess`, payload);

export const submitRankings = async (payload) =>
  await api.post(`/submitRanking`, payload);
